import * as DOM from "@common/dom";
import {getVal} from "@common/object";



export default class Form {
	#nameSpaces = {
		requestDataSelector: '.get-request-data'
	};
	#keyboardEnabled = true;

	constructor(form, onFormChecked, keyboard) {
		this.el = DOM.getElementsArray(form)[0];

		if (this.el) {
			this.callback = onFormChecked;

			this.#keyboardEnabled = typeof keyboard === 'boolean' ? keyboard : true;

			this.#init();
		}
	}
	
	#init = () => {
		/*--------------------------------------------------------------
		Инициализация полей формы
		---------------------------------------------------------------*/
		DOM.event(this.el, 'change paste cut input', this.#nameSpaces.requestDataSelector, () => {
			this.el.querySelectorAll('[data-valid="false"]').forEach(_el => {
				_el.removeAttribute('data-valid')
			});
		});

		/*--------------------------------------------------------------
		Проверка формы по нажатию Enter
		---------------------------------------------------------------*/
		if (this.#keyboardEnabled) {
			this.el.addEventListener('keypress', (e) => {
				if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
					e.preventDefault();
					this.checkForm();
				}
			})
		}
	}

	checkForm = () => {
		if (requiredDataValidation(this.el)) {
			/*--------------------------------------------------------------
			Form callback
			---------------------------------------------------------------*/
			if (typeof this.callback === 'function') {
				this.callback.call(this);
			}
			else {
				if (jsConfig['debugMode']) console.log('onFormChecked callback is not a function!');
			}
		}
		else {
			if (!document.querySelectorAll('.popup-window.__popup').length) {
				let firstInvalid = DOM.find('[data-valid="false"]', this.el)[0];

				if (firstInvalid) {
					let hH = (() => {
							let header = document.querySelector('header'),
								hst = document.querySelector('._hst');

							return (header ? header.offsetHeight : 0) + (hst ? hst.offsetHeight : 0)
						})(),
						scrollTop = DOM.offset(firstInvalid).top - hH - 100;

					if (scrollTop >= window.innerHeight || scrollTop <= window.scrollY) {
						window.scrollTo({
							top: scrollTop,
							behavior: "smooth"
						});
					}
				}
			}

			if (jsConfig['debugMode']) console.error('INVALID FORM');
		}
	}
}

export function requiredDataValidation(targets) {
	targets = DOM.getElementsArray(targets);

	targets = targets.length ? targets : [document];

	targets.forEach(target => {
		target.querySelectorAll('[required]').forEach(el => {
			let valid = false,
				type = el.type || el.getAttribute('data-type'),
				value = (el.value || '').trim(),
				requiredTarget = el.closest('.gt-required');

			requiredTarget = requiredTarget || el;

			if (DOM.hasClass(el, 'ignore')) {
				valid = true;
			}
			else {
				switch (type.toLowerCase()) {
					case 'email':
						valid = validateEmail(value);
						break;
					case 'checkbox':
						valid = !!el.checked;
						break;
					case 'radio':
						valid = Array.from(document.querySelectorAll(`input[type=radio][name=${ el.name }]`)).some(radio => radio.checked);
						break;
					case 'password':
					case 'tel':
					case 'hidden':
					case 'date':
					case 'textarea':
					case 'number':
					case 'text':
						valid = !!value.length;
						break;
					case 'file':
						if (el.closest('.gt-fileuploader')) {
							let id = el.id || '';

							id = id.replace('input:', '');

							if (getVal(window, 'gtFileuploader.' + id, []).length) {
								valid = true;
							}
						}
						else if (el.files.length) {
							valid = true;
						}

						break;
					default:
						valid = true;
						break;
				}
			}

			requiredTarget.setAttribute('data-valid', valid);

			if (!valid) {
				/*--------------------------------------------------------------
				Remove data-valid attribute when edit this required element
				---------------------------------------------------------------*/
				['change', 'paste', 'cut', 'input'].forEach(event => {
					el.addEventListener(event, () => {
						requiredTarget.removeAttribute('data-valid');
					}, {once: true});
				});
			}
		});
	});

	return targets.every(target => !target.querySelectorAll('[data-valid=false]').length);
}

export function validateEmail (email) {
	let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
	return reg.test(String(email).toLowerCase());
}

function validatePassword (password) {
	let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,})/;
	password.trim();
	return reg.test(String(password));
}
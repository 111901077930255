/*--------------------------------------------------------------
Импорт стилей
--------------------------------------------------------------*/
import '@scss/pages/_index.scss';

/*--------------------------------------------------------------
Swiper
--------------------------------------------------------------*/
import Swiper from 'swiper';
import 'swiper/css';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

/*--------------------------------------------------------------
Импорт модулей
--------------------------------------------------------------*/
import Range from "@plugins/range/Range";
import DomElementsInit from "@common/DomElementsInit";
import * as Template from '@common/template';
import UAPhoneInputMask from "@plugins/UAPhoneInputMask";
import * as API from "@common/api";
import * as DOM from "@common/dom";
import {getVal} from "@common/object";
import * as RequestData from "@common/requestData";
import Form from "@components/Form";




class HomePage {
	#timeout;


	constructor() {
		this.apiUrls = {
			bitrix: 'https://creditexpress.com.ua/api/bitrix.php'
		};

		this.selects = new DomElementsInit('select', ['.ui-select']);

		this.#swiperInit();
		this.#marqueeAnimation();
		this.#calculator.init();
		this.#phoneMaskInit();

		this.#consultationFormInit();
		this.#applicationFormInit();
		this.#calculatorFormInit();
	}

	/*--------------------------------------------------------------

	--------------------------------------------------------------*/
	#consultationFormInit = () => {
		let form = new Form('form[name="consultation"]', () => {
			this.#sendBitrixRequest(RequestData.get(form.el))
			.then(() => {
				DOM.find('input', form.el, input => {
				    input.value = '';
				});
			})
		});

		/*--------------------------------------------------------------
		Приведет к проверке и сбору данных формы
		---------------------------------------------------------------*/
		DOM.find('button', form.el, btn => {
			btn.addEventListener('click', form.checkForm);
		});
	};

	/*--------------------------------------------------------------

	--------------------------------------------------------------*/
	#applicationFormInit = () => {
		let form = new Form('form[name="online_application"]', () => {
			this.#sendBitrixRequest(RequestData.get(form.el))
			.then(() => {
				DOM.find('input[type="text"], input[type="tel"]', form.el, input => {
				    input.value = '';
				});
			})
		});

		/*--------------------------------------------------------------
		Приведет к проверке и сбору данных формы
		---------------------------------------------------------------*/
		DOM.find('button', form.el, btn => {
			btn.addEventListener('click', form.checkForm);
		});
	};

	/*--------------------------------------------------------------

	--------------------------------------------------------------*/
	#calculatorFormInit = () => {
		let form = new Form('form[name="calculator"]', () => {
			this.#sendBitrixRequest(RequestData.get(form.el, {
				sum: document.querySelector('input[name="calculator_sum"]').value,
				period: document.querySelector('input[name="calculator_period"]').value,
			}))
			.then(() => {
				DOM.find('input[type="text"], input[type="tel"]', form.el, input => {
				    input.value = '';
				});
			})
		});

		/*--------------------------------------------------------------
		Приведет к проверке и сбору данных формы
		---------------------------------------------------------------*/
		DOM.event(document, 'click', 'section.calculator .results button', form.checkForm);
	};

	/*--------------------------------------------------------------

	--------------------------------------------------------------*/
	#sendBitrixRequest = async (requestData = {}) => {
		return API.get(this.apiUrls.bitrix, {
			data: {
				method: 'crm.contact.add',
				method_data: {
					fields: {
						"NAME": requestData.name,
						"PHONE": [{
							"VALUE": requestData.phone,
							"VALUE_TYPE": "WORK",
						}],
						"SECOND_NAME": "",
						"LAST_NAME": "",
						"TYPE_ID": "CLIENT",
					},
				},
			}
		})
		.then(data => {
			let contactID = getVal(data, 'result');

			if (contactID) {
				let title = [
					(requestData.sum ? `сума: ${requestData.sum} грн.` : ''),
					(requestData.pledge ? `застава: ${requestData.pledge}` : ''),
					(requestData.period ? `термін: ${requestData.period} міс.` : ''),
					(requestData.name || ''),
					(requestData.phone || ''),
				].filter(s => !!s).map(s => String(s)).join(', ');

				title = title.charAt(0).toUpperCase() + title.slice(1);

				API.get(this.apiUrls.bitrix, {
					data: {
						method: 'crm.deal.add',
						method_data: {
							fields: {
								"CONTACT_ID": contactID,
								"OPPORTUNITY": requestData.sum || 0,
								"TITLE": title,
								"CURRENCY_ID": "UAH",
								"CATEGORY_ID": 14,
							},
						},
					},
				})
				.then(data => {

				});
			}
		});
	};

	/*--------------------------------------------------------------

	--------------------------------------------------------------*/
	#swiperInit = () => {
		new Swiper('.swiper', {
			// Optional parameters
			direction: 'horizontal',
			loop: true,
			modules: [Navigation, Pagination, Autoplay],

			autoplay: {
				delay: 3500,
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

			pagination: {
				el: '.swiper-pagination',
			},
		});
	};

	/*--------------------------------------------------------------

	--------------------------------------------------------------*/
	#marqueeAnimation = () => {
		const marqueeList = document.querySelector(".marquee");

		// Создаем вторую копию каждого элемента списка
		const originalHTML = marqueeList.innerHTML;
		marqueeList.innerHTML += originalHTML;

		const speed = 1.25;
		let scrollWidth = 0;

		const lis = marqueeList.getElementsByTagName("li");
		const totalLiWidth = Array.from(lis).reduce((totalWidth, li) => totalWidth + li.offsetWidth, 0) / 2;

		let isPaused = false;

		marqueeList.addEventListener('mouseenter', () => {
			isPaused = true;
		});

		marqueeList.addEventListener('mouseleave', () => {
			isPaused = false;
		});

		function scrollMarquee() {
			if(!isPaused) {
				if (-scrollWidth >= totalLiWidth) {
					scrollWidth = 0;
				} else {
					scrollWidth -= speed;
				}
				marqueeList.style.transform = `translateX(${scrollWidth}px)`;
			}
			requestAnimationFrame(scrollMarquee);
		}

		requestAnimationFrame(scrollMarquee);
	};

	/*--------------------------------------------------------------

	--------------------------------------------------------------*/
	#phoneMaskInit = () => {
		document.querySelectorAll('input[name="phone"]').forEach(el => {
			new UAPhoneInputMask(el);
		});
	};

	/*--------------------------------------------------------------

	--------------------------------------------------------------*/
	#calculator = (() => {
		let sum = 100000;
		let month = 6;
		let percent = 18;


		return {
			/*--------------------------------------------------------------

			--------------------------------------------------------------*/
			init: () => {
				new Range(document.querySelector('input[name="calculator_sum"]'), {
					values: [sum],
					range: [50000,1000000],
					step: 1000,
					single: true,
					onChange: (instance) => {
						this.#calculator.updateResults();
					},
				});

				new Range(document.querySelector('input[name="calculator_period"]'), {
					values: [month],
					range: [2,12],
					single: true,
					onChange: (instance) => {
						this.#calculator.updateResults();
					},
				});

				this.#calculator.updateResults();
			},

			/*--------------------------------------------------------------

			--------------------------------------------------------------*/
			updateResults: () => {
				clearTimeout(this.#timeout);

				this.#timeout = setTimeout(() => {
					let sum = document.querySelector('input[name="calculator_sum"]').value,
						period = document.querySelector('input[name="calculator_period"]').value,
						total = sum + sum * (percent / 100),
						monthly = sum * (percent / 100) / 12;


					Template.set({
						MONTHLY: monthly,
						TOTAL: monthly * period,
						MONTH_COUNT: period,
					}, document.querySelector('.calculator .results'), 'calculator-results-template', 'replace')
				}, 50);
			},
		}
	})();





}


document.addEventListener('DOMContentLoaded', function(){
	new HomePage();
});
import './range.scss';

import * as Template from '@common/template';
import {getVal} from "@common/object";
import AppStorage from "@common/AppStorage";
import WindowEvents from "@common/WindowEvents";


export default class Range {
	#classesNamespace = {
		mainContainer: 'ui-range',
		inputs: 'inputs',
		slider: 'slider',
		range: 'range',
		handle: 'handle',
	};

	#defaultSliderWidth = 300;

	#prevValues = {
		min: null,
		max: null,
	};


	constructor(node, options) {
		if (!options) options = {};

		if (node) {
			this.input = node;
			this.step = typeof options.step === 'number' && options.step > 0 ? options.step : null;
			this.singleMode = typeof options.single === 'boolean' ? options.single : false;
			this.applyHandler = typeof options.applyHandler === 'function' ? options.applyHandler : null;
			this.onChange = typeof options.onChange === 'function' ? options.onChange : null;
			this.onLoad = typeof options.onLoad === 'function' ? options.onLoad : null;
			this.i18n = this.#I18N[getVal(jsConfig, 'url.language', 'uk')];

			this.#prepareValues({
				range: options.range || this.input.getAttribute('data-range').split(','),
				values: options.values || this.input.getAttribute('value').split(','),
			});
			this.#templatesInit();
			this.#defineElements();
			this.#setValues();
			this.#setInputsValues();
			this.#setSliderPosition();
			this.#eventsInit();

			if (this.onLoad) this.onLoad.call(this, this);
		}
	}

	#defineElements = () => {
		Template.set([{
			RANGE_DISABLED_CLASS: this.rangeValues.min === this.rangeValues.max ? 'disabled' : '',
			IS_SLIDER_ENABLED: this.rangeValues.min !== this.rangeValues.max,
			IS_INPUTS_DISABLED: this.rangeValues.min === this.rangeValues.max,
			SINGLE_MODE: !this.singleMode,
			TOGGLE_APPLY_BTN: !!this.applyHandler && this.rangeValues.min !== this.rangeValues.max,
			IS_SINGLE: this.singleMode ? 'single' : '',
		}], this.input, 'ui-range-template', 'after');

		this.container = this.input.nextSibling;

		this.container.appendChild(this.input);

		this.inputs = this.container.querySelector('.' + this.#classesNamespace.inputs);
		this.applyBtn = this.inputs.querySelector('button');
		this.slider = this.container.querySelector('.' + this.#classesNamespace.slider);
		this.range = this.slider.querySelector('.' + this.#classesNamespace.range);

		if (this.range) {
			this.handle = this.range.querySelectorAll('.' + this.#classesNamespace.handle);
		}

		this.input.setAttribute('type', 'hidden');

		this.#setSliderWidth();
	};

	/*--------------------------------------------------------------
	Подготовка значений
	---------------------------------------------------------------*/
	#prepareValues = (options) => {
		let range = options['range'],
			values = options['values'];

		if (range) {
			range = Array.isArray(range) ? (() => {
				return !range.length ? [0,0] : (range.length > 1 ? range : [0, range[0]])
			})() : [0, range];

			range = range.map((n) => Math.abs(parseInt(n, 10) || 0)).sort((a, b) => a - b);

			this.rangeValues = {
				min: range[0],
				max: range[1],
			};
		}

		if (values) {
			values = Array.isArray(values) ? (() => {
				return !values.length ? [0,0] : (values.length > 1 ? values : [0, values[0]])
			})() : [0, values];

			values = values.map((n) => Math.abs(parseInt(n, 10) || 0)).sort((a, b) => a - b);

			if (values[0] < range[0] || values[0] > range[1]) values[0] = range[0];
			if (values[1] > range[1] || values[1] < range[0]) values[1] = range[1];

			this.values = {
				min: values[0],
				max: values[1],
			};

			/*--------------------------------------------------------------
			Установка базовых значений
			---------------------------------------------------------------*/
			this.#prevValues.min = values[0];
			this.#prevValues.max = values[1];
		}
	};

	/*--------------------------------------------------------------
	Установка ширины слайдера
	---------------------------------------------------------------*/
	#setSliderWidth = () => {
		this.container.style.maxWidth = '';

		if (!!this.container.offsetWidth) {
			this.container.style.maxWidth = (this.container.offsetWidth || this.#defaultSliderWidth) + 'px';
		}
		else {
			this.container.style.maxWidth =  300 + 'px';
		}

		if (this.range) {
			this.sliderWidth = (this.slider.offsetWidth || this.#defaultSliderWidth) - Array.from(this.handle).reduce((sum, el) => {
				sum += el.offsetWidth;
				return sum;
			}, 0);
		}
		else {
			this.sliderWidth = this.slider.offsetWidth || this.#defaultSliderWidth;
		}

		this.pixelRatio = this.sliderWidth / (this.rangeValues.max - this.rangeValues.min);
	};

	/*--------------------------------------------------------------
	Установка позиций слайдера
	---------------------------------------------------------------*/
	#setSliderPosition = () => {
		if (this.range) {
			let left = (this.values.min - this.rangeValues.min) * this.pixelRatio,
				width = (this.slider.offsetWidth || this.#defaultSliderWidth) - (this.sliderWidth - (this.values.max - this.values.min) * this.pixelRatio);

			this.range.style.left  = left  / (this.slider.offsetWidth || this.#defaultSliderWidth) * 100 + '%';
			this.range.style.width = width / (this.slider.offsetWidth || this.#defaultSliderWidth) * 100 + '%';
		}
	};

	/*--------------------------------------------------------------
	Установка значений
	---------------------------------------------------------------*/
	#setValues = (target, val) => {
		if (typeof target === 'string' && typeof val !== 'undefined') {
			if (target === 'min') {
				this.values[target] = val < this.rangeValues.min ? this.rangeValues.min : (val > this.values.max ? this.values.max : val);
			}
			else {
				this.values[target] = val > this.rangeValues.max ? this.rangeValues.max : (val < this.values.min ? this.values.min : val);
			}

			if (this.#prevValues.min !== this.values.min || this.#prevValues.max !== this.values.max) {
				this.#prevValues.min !== this.values.min;
				this.#prevValues.max !== this.values.max;
			}

			this.#toggleApplyBtn(true);

			if (typeof this.onChange === 'function') {
				this.onChange.call(this, this);
			}
		}

		/*--------------------------------------------------------------
		Устанавливаем шаг для перетягивания
		---------------------------------------------------------------*/
		if (this.step) {
			this.values[target] = Math.round(this.values[target] / this.step) * this.step;
		}

		/*--------------------------------------------------------------
		Установка значений в срытый, основной инпут
		---------------------------------------------------------------*/
		this.#setHiddenInputValue();
	};

	/*--------------------------------------------------------------
	Установка значений в поля ввода
	--------------------------------------------------------------*/
	#setInputsValues = () => {
		this.inputs.querySelectorAll('input').forEach((el, i) => {
			el.value = !i && !this.singleMode ? this.values.min : this.values.max;
		});
	};

	/*--------------------------------------------------------------
	Установка значений в скрытый, основной инпут
	---------------------------------------------------------------*/
	#setHiddenInputValue = () => {
		this.input.value = !this.singleMode ? this.values.min + ',' + this.values.max : this.values.max;
	};

	/*--------------------------------------------------------------
	Переключение отображения кнопки
	---------------------------------------------------------------*/
	#toggleApplyBtn = (toggle) => {
		if (this.applyBtn) {
			// if (toggle) this.applyBtn.classList.add('show');
			// else this.applyBtn.classList.remove('show');

			this.applyBtn.disabled = !toggle;
		}
	};

	/*--------------------------------------------------------------
	Инициализация событий
	---------------------------------------------------------------*/
	#eventsInit = () => {
		if (this.range) {
			/*--------------------------------------------------------------
			Перетягивание ползунков слайдера
			---------------------------------------------------------------*/
			this.handle.forEach((el, index) => {
				['mousedown', 'touchstart'].forEach((event) => {
					el.addEventListener(event, (e) => {
						let startX = this.#getXpos(e) || this.#getOffset(e.currentTarget).left + e.currentTarget.offsetWidth / 2,
							target = !index && !this.singleMode ? 'min' : 'max',
							startValue = this.values[target];

						let moveSliderHandle = (e) => {
							this.#setValues(target, Math.round(startValue + (this.#getXpos(e) - startX) / this.pixelRatio));
							this.#setInputsValues();
							this.#setSliderPosition();

							if (!e || !e.type.includes('touch')) {
								e.preventDefault();
							}
						};

						['mousemove', 'touchmove'].forEach((event) => {
							document.addEventListener(event, moveSliderHandle, {passive: event.includes('touch')});
						});

						['mouseup', 'touchend'].forEach((event) => {
							document.addEventListener(event, () => {
								['mousemove', 'touchmove'].forEach((event) => {
									document.removeEventListener(event, moveSliderHandle);
								});
							}, {once: true, passive: event.includes('touch')});
						});

						if (!e || !e.type.includes('touch')) {
							e.preventDefault();
						}
					}, {passive: event.includes('touch')});
				});
			});

			/*--------------------------------------------------------------
			Клик по слайдеру
			---------------------------------------------------------------*/
			['mousedown', 'touchstart'].forEach((event) => {
				this.slider.addEventListener(event, (e) => {
					if (e.target === this.slider || e.target === this.range) {
						let target,
							sliderOffset = this.#getOffset(this.slider).left,
							x = this.#getXpos(e) - sliderOffset,
							handleIndent;

						if (this.singleMode) {
							target = 'max';
						}
						else if (this.handle.length === 2) {
							let handle0 = this.#getOffset(this.handle[0]).left - sliderOffset,
								handle1 = this.#getOffset(this.handle[1]).left - sliderOffset;

							if (x < handle0) {
								target = 'min';
							}
							else if (x > handle1 + this.handle[1].offsetWidth) {
								target = 'max';
							}
							else {
								if (x < handle1 - (handle1 - (handle0 + this.handle[0].offsetWidth)) / 2) {
									target = 'min';
								}
								else {
									target = 'max';
								}
							}
						}

						handleIndent = target === 'min' || this.singleMode ? (this.handle[0].offsetWidth / 2) : (this.handle[0].offsetWidth + this.handle[1].offsetWidth / 2);

						this.#setValues(target, Math.round((x - handleIndent) / this.pixelRatio) + this.rangeValues.min);
						this.#setInputsValues();
						this.#setSliderPosition();

						/*--------------------------------------------------------------
						Вызов события mousedown на ползунке
						---------------------------------------------------------------*/
						this.handle[target === 'min' || this.singleMode ? 0 : 1].dispatchEvent( new CustomEvent(event) );
					}
				}, {passive: event.includes('touch')});
			});
		}

		/*--------------------------------------------------------------
		Редактирование полей со значениями
		---------------------------------------------------------------*/
		this.inputs.querySelectorAll('input').forEach((el, index) => {
			let target = !index && !this.singleMode ? 'min' : 'max';

			['change', 'input', 'cut', 'paste'].forEach(event => {
				el.addEventListener(event, e => {
					let val = parseInt(e.currentTarget.value.trim(), 10) || 0;

					e.currentTarget.value = val;

					this.#setValues(target, val);

					if (event === 'change') {
						this.#setInputsValues();
					}

					this.#setSliderPosition();
				});
			});
		});

		/*--------------------------------------------------------------
		Запустить функцию обработчик по нажатию кнопки
		---------------------------------------------------------------*/
		if (this.applyBtn && typeof this.applyHandler === 'function') {
			this.applyBtn.addEventListener('click', () => {
				this.applyHandler.call(this, this);
				this.#toggleApplyBtn(false);
			});
		}

		/*--------------------------------------------------------------
		Пересчет ширины слайдера при изменении размера окна
		---------------------------------------------------------------*/
		WindowEvents.add('resize', this.#setSliderWidth);
	};

	/*--------------------------------------------------------------
	Получение позиции элемента
	---------------------------------------------------------------*/
	#getOffset = (el) => {
		let rect = el.getBoundingClientRect();

		return {
			left: rect.left + window.scrollX,
			top: rect.top + window.scrollY
		};
	};

	/*--------------------------------------------------------------
	Получение X-координаты в зависимости от события (клик/тап)
	---------------------------------------------------------------*/
	#getXpos = e => e.touches ? (e.touches[0] ? e.touches[0].clientX : 0) : e.clientX;

	/*--------------------------------------------------------------
	Обновление слайдера
	---------------------------------------------------------------*/
	update = (options) => {
		this.#prepareValues(options);

		if (options.hasOwnProperty('sliderWidth') && options['sliderWidth']) {
			this.#setSliderWidth();
			this.#setSliderPosition();
		}
	};



	/*--------------------------------------------------------------
	Сохранение шаблонов
	---------------------------------------------------------------*/
	#templatesInit = () => {
		/*--------------------------------------------------------------
		Основной контейнер
		---------------------------------------------------------------*/
		let rangeContainer =
			'<div class="' + this.#classesNamespace.mainContainer + ' ${RANGE_DISABLED_CLASS}">' +
			'<div class="' + this.#classesNamespace.inputs + '">' +
			'<input type="number" aria-label="' + this.i18n.labelMin + '" name="min" value="" data-x-toggle="${SINGLE_MODE}" data-x-disabled="${IS_INPUTS_DISABLED}">' +
			'<input type="number" aria-label="' + this.i18n.labelMax + '" name="max" value="" data-x-disabled="${IS_INPUTS_DISABLED}">' +
			'<button type="button" aria-label="' + this.i18n.applyBtn + '" disabled data-x-toggle="${TOGGLE_APPLY_BTN}"></button>' +
			'</div>' +
			'<div class="' + this.#classesNamespace.slider + ' ${IS_SINGLE}">' +
			'<div class="' + this.#classesNamespace.range + '" data-x-toggle="${IS_SLIDER_ENABLED}">' +
			'<div class="' + this.#classesNamespace.handle + '" data-x-toggle="${SINGLE_MODE}"></div>' +
			'<div class="' + this.#classesNamespace.handle + '"></div>' +
			'</div>' +
			'</div>' +
			'</div>';

		AppStorage.set('templates.ui-range-template', rangeContainer);
	};

	/*--------------------------------------------------------------
	Языковые переводы
	---------------------------------------------------------------*/
	#I18N = {
		uk: {
			labelMin: 'Введіть мінімальне значення',
			labelMax: 'Введіть максимальне значення',
			applyBtn: 'Застосувати',
		},
		ru: {
			labelMin: 'Введите минимальное значение',
			labelMax: 'Введите максимальное значение',
			applyBtn: 'Применить',
		},
		en: {
			labelMin: 'Enter the minimum value',
			labelMax: 'Enter the maximum value',
			applyBtn: 'Apply',
		},
	};
}
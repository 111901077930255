/*--------------------------------------------------------------
Get data from all '.get-request-data' elements
and put to Window main object
---------------------------------------------------------------*/
import * as DOM from "@common/dom";
import AppStorage from "@common/AppStorage";


export function set(target, obj, action){
    obj = typeof obj === 'object' ? obj : {};

    if (action && typeof action === 'function') action.call(this);

    obj = get(target, obj);

    if (Object.keys(obj).length) {
        AppStorage.set('requestData', obj);
    }
}

/*--------------------------------------------------------------
Get data from all '.get-request-data' elements
---------------------------------------------------------------*/
export function get(targets, obj){
    targets = DOM.getElementsArray(targets);

    targets = targets.length ? targets : [document];

    obj = typeof obj === 'object' ? obj : {};

    targets.forEach(target => {
        target.querySelectorAll('.get-request-data').forEach(el => {
            let val = el.value || el.innerText,
                name = el.name || el.getAttribute('data-name'),
                type = el.type || el.getAttribute('data-type');

            if (!name) {
                if (jsConfig['debugMode']) {
                    console.error('requestData.set: Need to set "name" or "data-name" attribute for ' + el.tagName + '[type=' + type +']');
                }

                return false;
            }

            if (type === 'checkbox') {
                val = el.checked ? '1' : '0';
            }
            else if (type === 'radio') {
                val = null;

                target.querySelectorAll(`[type=radio][name=${ name }]`).forEach(radioEl => {
                    if (radioEl.checked) {
                        val = radioEl.value;
                    }
                });
            }
            else if (type === 'file') {
                let id = el.id || '';

                id = id.replace('input:', '');

                if (window.gtFileuploader && window.gtFileuploader[id]) {
                    val = window.gtFileuploader[id];
                }
            }

            if (typeof val !== 'undefined' && typeof name !== 'undefined' && name.length && !obj.hasOwnProperty(name)) {
                obj[name] = val;
            }

        })
    })

    return obj;
}

/*--------------------------------------------------------------
Get sorting method from gt table
---------------------------------------------------------------*/
export function getSortingMethod(table){
    table = DOM.getElementsArray(table)[0];

    if (table) {
        let _cell = DOM.find('.tab-head .tab-cell[class*="sorting_"]', table)[0];

        if  (_cell) {
            return {
                order_by: _cell.getAttribute('data-orderby'),
                order_method: Array.from(_cell.classList).reduce((str, c) => {
                    if (c.includes('sorting_')) str = c.split('_')[1];
                    return str;
                }, ''),
            };
        }
    }

    return {}
}
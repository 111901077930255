export default class UAPhoneInputMask {
	#mask = '+38 0** *** ** **';
	#maxNumberLength = 13;


	constructor(input) {
		if (input) {
			this.input = input;

			this.#init();
		}
	}


	#init = () => {
		this.input.value = this.#setVal();

		['change', 'cut', 'paste', 'input'].forEach(event => {
			this.input.addEventListener(event, () => {
				this.input.value = this.#setVal();
			});
		});
	};


	#setVal = (value, required, result) => {
		if (typeof value === 'undefined') {
			if (this.input.value.includes('+')) {
				this.input.value = '+' + this.input.value.replace(/\+/g, '');
			}

			value = this.input.value.match(/[+\d]+/g);

			if (value) {
				value = value.join('').slice(0, this.#maxNumberLength);
			}
		}

		result = result || '';

		if (value && value.length) {
			required = required || this.#mask;

			if (required.length && result.length < this.#mask.length) {
				if (required[0] === '*' || value[0] === required[0]) {
					result += value[0];
					value = value.slice(1);
				}
				else {
					result += required[0];
				}

				required = required.slice(1);
			}
			else {
				result += value[0];

				value = value.slice(1);
			}

			return this.#setVal( value, required, result )
		}

		return result
	};
}